



















import DrashTooltip  from '@/components/DrashTooltip.vue'
// import {getMetaInfo} from '@/utils/getMetaInfo'

export default {
  // metaInfo: getMetaInfo('school'),
  components: {DrashTooltip}
}
